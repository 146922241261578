<template>
  <section id="NosPreocupamos" class="weCare">
    <div class="__content">
      <div class="w-100 d-flex justify-content-start ms-8">
        <h2>
          <span style="color: #14993c"> Español </span>
        </h2>
      </div>
      <input
        class="form-control form-control-lg wet-form fw-bold text-center mb-2"
        type="text"
        v-model="dataES.title"
      />

      <input
        class="form-control wet-form text-center mb-2"
        type="text"
        v-model="dataES.description"
      />
      <div class="__card_container">
        <div
          class="card shadow ___bases_card"
          v-for="card in dataES.cards"
          :key="card.id"
        >
          <img
            :src="require(`../../assets/${card.img}`)"
            :alt="card.name"
            loading="lazy"
          />

          <input
            class="form-control form-control-lg wet-form fw-bold text-center mb-2"
            type="text"
            v-model="card.title"
          />

          <textarea
            class="form-control wet-form text-center mb-2"
            rows="5"
            type="text"
            v-model="card.text"
          />
        </div>
      </div>

      <div class="w-100 d-flex justify-content-start ms-8">
        <button
          class="btn btn-success btn-lg mt-3 mb-3"
          @click="syncContent('ES')"
        >
          Guardar
        </button>
      </div>
    </div>

    <div class="w-100">
      <hr class="pill my-5" />
    </div>

    <div class="__content">
      <div class="w-100 d-flex justify-content-start ms-8">
        <h2>
          <span style="color: #14993c"> English </span>
        </h2>
      </div>
      <input
        class="form-control form-control-lg wet-form fw-bold text-center mb-2"
        type="text"
        v-model="dataEN.title"
      />

      <input
        class="form-control wet-form text-center mb-2"
        type="text"
        v-model="dataEN.description"
      />
      <div class="__card_container">
        <div
          class="card shadow ___bases_card"
          v-for="card in dataEN.cards"
          :key="card.id"
        >
          <img
            :src="require(`../../assets/${card.img}`)"
            :alt="card.name"
            loading="lazy"
          />

          <input
            class="form-control form-control-lg wet-form fw-bold text-center mb-2"
            type="text"
            v-model="card.title"
          />

          <textarea
            class="form-control wet-form text-center mb-2"
            rows="5"
            type="text"
            v-model="card.text"
          />
        </div>
      </div>

      <div class="w-100 d-flex justify-content-start ms-8">
        <button
          class="btn btn-success btn-lg mt-3 mb-3"
          @click="syncContent('EN')"
        >
          Save
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { getSection, putSection } from "../../helpers/contenido";

export default {
  name: "NosPreocupamos",
  data() {
    return {
      dataES: {
        title: "Nos preocupamos por ti",
        description: "Desde nuestras bases",
        cards: [
          {
            id: 1,
            img: "img/proposito.webp",
            title: "Propósito",
            text: "Juntar a las nuevas generaciones con profesionales y empresarios con experiencia para construir un mundo mejor.",
          },
          {
            id: 2,
            img: "img/vision.webp",
            title: "Visión",
            text: "Ser una plataforma global que dé acceso a los mejores talentos, prácticas, redes y conocimientos para construir empresas.",
          },
          {
            id: 3,
            img: "img/mision.webp",
            title: "Misión",
            text: "Ofrecer la mejor hoja de ruta y programas para ayudar a que emprendedores y empresarios cumplan sus sueños.",
          },
        ],
      },

      dataEN: {
        title: "Nos preocupamos por ti",
        description: "Desde nuestras bases",
        cards: [
          {
            id: 1,
            img: "img/proposito.webp",
            title: "Propósito",
            text: "Juntar a las nuevas generaciones con profesionales y empresarios con experiencia para construir un mundo mejor.",
          },
          {
            id: 2,
            img: "img/vision.webp",
            title: "Visión",
            text: "Ser una plataforma global que dé acceso a los mejores talentos, prácticas, redes y conocimientos para construir empresas.",
          },
          {
            id: 3,
            img: "img/mision.webp",
            title: "Misión",
            text: "Ofrecer la mejor hoja de ruta y programas para ayudar a que emprendedores y empresarios cumplan sus sueños.",
          },
        ],
      },
    };
  },
  methods: {
    async fetchContent() {
      const section = await getSection("careAboutYou");
      const sectionES = section.filter((section) => section.language == "ES");
      const sectionEN = section.filter((section) => section.language == "EN");

      this.sectionES = sectionES;
      this.sectionEN = sectionEN;

      this.dataES = sectionES[0].components;

      this.dataEN = sectionEN[0].components;
    },
    async syncContent(lan) {
      if (lan === "ES") {
        const content = {
          id: this.sectionES[0]._id,
          section: "careAboutYou",
          components: this.dataES,
          language: "ES",
        };
        await putSection(content);
      } else {
        const content = {
          id: this.sectionEN[0]._id,
          section: "careAboutYou",
          components: this.dataEN,
          language: "EN",
        };
        await putSection(content);
      }
    },
  },
  watch: {},
  mounted() {
    this.fetchContent();
  },
};
</script>

<style scoped lang="scss">
hr.pill {
  height: 0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}
.weCare {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 50vh;
  width: 100%;

  .__content {
    max-width: 1366px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .__card_container {
    display: grid;
    grid-gap: 25px 0;
    grid-template-columns: 1fr;
    width: 100%;

    .___bases_card {
      border-radius: 6px;
      justify-content: center;
      position: relative;
      padding: 44px;
      text-overflow: ellipsis;
      min-height: 275px;
      max-height: 355px;
      max-width: 100%;
      margin: 70px 0 0;
    }

    .___bases_card img {
      aspect-ratio: 1;
      width: 139px;
      position: absolute;
      place-self: center;
      top: -70px;
    }

    .___bases_card p {
      overflow: hidden;
    }
  }
}

@media (min-width: 992px) {
  .weCare {
    .__card_container {
      grid-gap: 0 3%;
      grid-template-columns: repeat(3, 1fr);
      margin: 54px 0 0;

      .___bases_card {
        min-height: 355px;
        max-height: 455px;
      }
    }
  }
}
</style>
